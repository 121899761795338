import * as React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import CampIntro from "../components/camp-intro";
import CampLevels from "../components/camp-levels";
import CampDestinations from "../components/camp-destinations";
import CampRenting from "../components/camp-renting";
import CampResult from "../components/camp-result";

const CampPage = ({ data }) => {
  const [level, setLevel] = React.useState(null);
  const [destination, setDestination] = React.useState(null);
  const [isRenting, setIsRenting] = React.useState(null);
  const { formatMessage } = useIntl();
  const title = formatMessage({ id: "campTitle" });
  const { levels, destinations, products } = data;
  const isLevel = ({ title }) => title === level;
  const levelColor = levels.nodes.find(isLevel)?.color;

  return (
    <Layout>
      <Seo title={title} />
      <section
        className="camp"
        style={{
          "--color-level": levelColor,
          "--color-heading": levelColor === "#EB602F" ? "black" : "white",
        }}
      >
        <CampIntro />
        <CampLevels levels={levels} setLevel={setLevel} />
        <CampDestinations
          destinations={destinations}
          setDestination={setDestination}
          level={level}
          products={products}
        />
        <CampRenting setIsRenting={setIsRenting} destination={destination} />
        <CampResult
          products={products}
          destination={destination}
          level={level}
          isRenting={isRenting}
        />
      </section>
    </Layout>
  );
};

export default CampPage;

export const query = graphql`
  query ($locale: String!) {
    levels: allContentfulLevel(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: order }
    ) {
      nodes {
        contentful_id
        title
        alternativeGauge {
          title
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        intro {
          intro
        }
        color
        order
      }
    }

    destinations: allContentfulDestination(
      filter: { node_locale: { eq: $locale }, inProject: { eq: false } }
      sort: { fields: title }
    ) {
      nodes {
        contentful_id
        title
        intro {
          intro
        }
        campImage {
          description
          title
          gatsbyImageData(quality: 80)
        }
        badge {
          description
          title
          file {
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }

    products: allContentfulProduct(
      filter: {
        node_locale: { eq: $locale }
        destination: { inProject: { eq: false }, partner: { ne: true } }
      }
    ) {
      nodes {
        contentful_id
        title
        price
        campPrice
        campPriceWithBike
        campBookingUrl
        campBookingUrlWithBike
        duration
        destination {
          title
          campImage {
            description
            title
            gatsbyImageData(quality: 80)
          }
          badge {
            description
            title
            file {
              url
              details {
                image {
                  width
                  height
                }
              }
            }
          }
        }
        level {
          title
        }
      }
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
