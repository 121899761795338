import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SwiperCore, { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Link from "./link";

SwiperCore.use([A11y]);

const CampLevels = ({ levels, setLevel }) => {
  const { formatMessage } = useIntl();
  const heading = formatMessage({ id: "campLevelsHeading" });

  const handleChange = (event) => {
    setLevel(event.target.value);
  };

  return (
    <>
      <div className="camp-levels">
        <div className="container">
          <fieldset>
            <legend>
              <h2
                className="heading"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            </legend>
            <Swiper className="camp-slideshow" slidesPerView="auto">
              {levels.nodes.map((level) => (
                <SwiperSlide key={level.contentful_id}>
                  <input
                    type="radio"
                    name="level"
                    value={level.title}
                    onChange={handleChange}
                    id={`level-${level.contentful_id}`}
                    aria-describedby={`level-description-${level.contentful_id}`}
                    required
                  />
                  <div style={{ "--color-level": level.color }}>
                    <label htmlFor={`level-${level.contentful_id}`}>
                      {level.title}
                    </label>
                    <img
                      src={level.alternativeGauge.file.url}
                      alt={level.alternativeGauge.title}
                    />
                    <p id={`level-description-${level.contentful_id}`}>
                      {level.intro.intro}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <Link className="link" href="/levels/">
              <FormattedMessage id="campLevelsButton" />
            </Link>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default CampLevels;
