import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";
import SwiperCore, { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

SwiperCore.use([A11y]);

const CampRenting = ({ setIsRenting, destination }) => {
  const rentingEl = React.useRef(null);
  const { formatMessage } = useIntl();
  const heading = formatMessage({ id: "campRentingHeading" });
  const isRenting = formatMessage({ id: "campRentingTrue" });
  const isNotRenting = formatMessage({ id: "campRentingFalse" });
  const text = formatMessage({ id: "campRentingText" });

  const handleChange = (event) => {
    setIsRenting(event.target.value === "true" ? true : false);
  };

  React.useEffect(() => {
    rentingEl.current?.scrollIntoView({ behavior: "smooth" });
  }, [destination]);

  if (!destination) return null;

  return (
    <div className="camp-renting" ref={rentingEl}>
      <div className="container">
        <fieldset>
          <legend>
            <h2
              className="heading"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          </legend>
          <Swiper className="camp-slideshow" slidesPerView="auto">
            <SwiperSlide>
              <input
                type="radio"
                name="renting"
                value="false"
                onClick={handleChange}
                id="renting-false"
              />
              <div className="camp-renting-image">
                <div>
                  <label // eslint-disable-line jsx-a11y/label-has-associated-control
                    htmlFor="renting-false"
                    dangerouslySetInnerHTML={{ __html: isNotRenting }}
                  />
                  <StaticImage src="../assets/bike.jpg" quality={80} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <input
                type="radio"
                name="renting"
                value="true"
                onClick={handleChange}
                id="renting-true"
                required
              />
              <div className="camp-renting-image">
                <div>
                  <label // eslint-disable-line jsx-a11y/label-has-associated-control
                    htmlFor="renting-true"
                    dangerouslySetInnerHTML={{ __html: isRenting }}
                  />
                  <StaticImage src="../assets/bike.jpg" quality={80} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="camp-renting-text">
                <div dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </SwiperSlide>
          </Swiper>
        </fieldset>
      </div>
    </div>
  );
};

export default CampRenting;
