import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedNumber, useIntl } from "react-intl";
import SwiperCore, { A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

SwiperCore.use([A11y]);

const CampDestinations = ({
  level,
  destinations,
  setDestination,
  products,
}) => {
  const destinationsEl = React.useRef(null);
  const { formatMessage } = useIntl();
  const heading = formatMessage({ id: "campDestinationsHeading" });

  const handleChange = (event) => {
    setDestination(event.target.value);
  };

  const getProduct = (destination) => {
    const isLevel = (product) => product.level?.title === level;
    const isDestination = (product) =>
      product.destination?.title === destination.title;

    return products.nodes.filter(isLevel).find(isDestination);
  };

  React.useEffect(() => {
    destinationsEl.current?.scrollIntoView({ behavior: "smooth" });
  }, [level]);

  if (!level) return null;

  return (
    <div className="camp-destinations" ref={destinationsEl}>
      <div className="container">
        <fieldset>
          <legend>
            <h2
              className="heading"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          </legend>
          <Swiper className="camp-slideshow" slidesPerView="auto">
            {destinations.nodes.map((destination) => (
              <SwiperSlide key={destination.contentful_id}>
                <input
                  type="radio"
                  name="destination"
                  value={destination.title}
                  onChange={handleChange}
                  id={`destination-${destination.contentful_id}`}
                  aria-describedby={`destination-description-${destination.contentful_id}`}
                  required
                />
                <div>
                  <div className="camp-destinations-image">
                    <div>
                      <label
                        htmlFor={`destination-${destination.contentful_id}`}
                      >
                        {destination.title}
                      </label>
                      <img
                        src={destination.badge.file.url}
                        alt={destination.badge.title}
                        width={destination.badge.file.details.image.width}
                        height={destination.badge.file.details.image.height}
                        loading="lazy"
                        decoding="async"
                      />
                    </div>
                    {destination.campImage && (
                      <GatsbyImage
                        image={destination.campImage.gatsbyImageData}
                        alt={destination.campImage.title}
                      />
                    )}
                  </div>
                  <div className="camp-destinations-text">
                    <p
                      id={`destination-description-${destination.contentful_id}`}
                    >
                      {destination.intro.intro}
                    </p>
                    <span>{getProduct(destination).duration}</span>
                    <strong>
                      <FormattedNumber
                        value={getProduct(destination).campPrice}
                        style="currency" // eslint-disable-line
                        currency="EUR"
                        minimumFractionDigits={0}
                      />
                    </strong>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </fieldset>
      </div>
    </div>
  );
};

export default CampDestinations;
