import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Countdown, { zeroPad } from "react-countdown";

const CampIntro = () => {
  const { formatMessage } = useIntl();
  const heading = formatMessage({ id: "campIntroHeading" });
  const content = formatMessage({ id: "campIntroContent" });

  const renderer = ({ days, hours, minutes, completed }) => {
    const totalMinutes = days * 24 * 60 + hours * 60 + minutes;
    if (completed) return <FormattedMessage id="campIntroTimesUp" />;
    return (
      <>
        <FormattedMessage id="campIntroYouHave" />{" "}
        <span>{zeroPad(totalMinutes, 5)} minutes</span>{" "}
        <FormattedMessage id="campIntroToBook" />. <small>(No pressure!)</small>
      </>
    );
  };

  return (
    <header className="camp-intro">
      <div className="container">
        <div>
          <h1 dangerouslySetInnerHTML={{ __html: heading }} />
          <p>
            <FormattedMessage id="campIntroParagraph" />
          </p>
          <p className="camp-intro-countdown">
            <Countdown date="2021-12-08" renderer={renderer} />
          </p>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </header>
  );
};

export default CampIntro;
