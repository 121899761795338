import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";

const query = graphql`
  query {
    pages: allContentfulPage {
      nodes {
        node_locale
        contentful_id
        title
        slug
      }
    }
  }
`;

const CampResult = ({ products, destination, level, isRenting }) => {
  const resultEl = React.useRef(null);
  const { locale, formatMessage } = useIntl();
  const { pages } = useStaticQuery(query);
  const isActiveLocale = ({ node_locale }) => node_locale === locale;
  const heading = formatMessage({ id: "campResultHeading" });
  const content = formatMessage({ id: "campResultContent" });
  const isLevel = (product) => product.level?.title === level;
  const isDestination = (product) => product.destination?.title === destination;
  const product = products.nodes.filter(isLevel).find(isDestination);
  const bookingUrl = isRenting
    ? product?.campBookingUrlWithBike
    : product?.campBookingUrl;
  const publicPrice = isRenting ? product?.campPriceWithBike : product?.price;

  const getPageById = (id) => {
    const isId = ({ contentful_id }) => contentful_id === id;
    return pages.nodes.filter(isActiveLocale).find(isId);
  };

  const termsPage = getPageById("4P2nU7ECuj4yDCBZDGPhcU");

  React.useEffect(() => {
    resultEl.current?.scrollIntoView({ behavior: "smooth" });
  }, [isRenting]);

  if (isRenting === null) return null;

  return (
    <footer className="camp-result" ref={resultEl}>
      <div className="container">
        <div>
          <h2
            className="heading"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
          <div className="camp-result-grid">
            {bookingUrl && (
              <div className="camp-result-booking">
                <a href={bookingUrl} target="_blank" rel="noreferrer">
                  <FormattedMessage id="campResultBooking" />
                </a>
              </div>
            )}
            <div className="camp-result-image">
              <div>
                <p>{destination}</p>
                <img
                  src={product.destination.badge.file.url}
                  alt={product.destination.badge.title}
                  width={product.destination.badge.file.details.image.width}
                  height={product.destination.badge.file.details.image.height}
                  loading="lazy"
                  decoding="async"
                />
              </div>
              {product.destination.campImage && (
                <GatsbyImage
                  image={product.destination.campImage.gatsbyImageData}
                  alt={product.destination.campImage.title}
                />
              )}
            </div>
            <div className="camp-result-price">
              <div>
                <div className="camp-result-price-main">
                  <p>
                    {level}
                    <br />
                    {destination}
                  </p>
                  {isRenting && (
                    <p>
                      <FormattedMessage id="campResultRenting" />
                    </p>
                  )}
                  <p>{product.duration}</p>
                  <p>
                    <FormattedNumber
                      value={product.campPrice}
                      style="currency" // eslint-disable-line
                      currency="EUR"
                      minimumFractionDigits={0}
                    />
                  </p>
                </div>
                <div className="camp-result-price-footer">
                  <p>
                    <FormattedMessage id="campResultPublicPrice" />{" "}
                    <FormattedNumber
                      value={publicPrice}
                      style="currency" // eslint-disable-line
                      currency="EUR"
                      minimumFractionDigits={0}
                    />
                  </p>
                  <small>
                    <FormattedMessage id="campResultAllInclusive" />
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <div className="camp-result-links">
            <a
              className="link"
              href="https://api.whatsapp.com/send?phone=33652159262"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="campResultContact" />
            </a>
            <a
              className="link"
              href={`/${locale}/faq/`}
              target="_blank"
              rel="noreferrer"
            >
              FAQ
            </a>
            {termsPage && (
              <a
                className="link"
                href={`/${locale}/${termsPage.slug}/`}
                target="_blank"
                rel="noreferrer"
              >
                {termsPage.title}
              </a>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CampResult;
